import { Injectable } from '@angular/core';
import { ArboService } from 'src/app/services/arbo.service';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  constructor(
    private arboService: ArboService,
    private httpService: HttpService
  ) { }

  create(data)  { return this.arboService.create('Client',data); }
  get(id)       { return this.arboService.get('Client',id); }
  update(data)  { return this.arboService.update('Client',data); }
  getMemberships() { return this.httpService.get(environment.arboUrl+`/Client/memberships`); }
  // getByCPF(cpf) { return this.httpService.get(environment.arboUrl+`/Profile/cpf/${cpf}`); }
  // getByCelular(celular) { return this.httpService.get(environment.arboUrl+`/Profile/celular/${celular}`); }
}
